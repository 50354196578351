import ApiService from "@/api/api.service";
import { AxiosPromise } from "axios";
import { Database } from "@/types/Database";

export const apiDatabase = (id: number): AxiosPromise<Database> => {
  return ApiService.get(`api/billing/databases/${id}`);
};

export const apiDatabases = (): AxiosPromise<Database[]> => {
  return ApiService.get("api/billing/databases");
};
