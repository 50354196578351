
import { defineComponent, reactive, ref, onMounted } from "vue";
import TheDatabase from "@/components/TheDatabase.vue";
import { Database } from "@/types/Database";
import { apiDatabases } from "@/api/databases";
import { useStore } from "vuex";

export default defineComponent({
  name: "TheNavBar",
  components: {
    TheDatabase,
  },
  setup() {
    const database: Database[] = reactive([]);
    const isLoading = ref(false);

    const store = useStore();

    const loadDatabases = async () => {
      isLoading.value = true;
      const { data } = await apiDatabases();
      database.splice(0, database.length, ...data);
      await store.dispatch("database/setDatabases", database);
      isLoading.value = false;
    };

    onMounted(loadDatabases);

    return { isLoading, database };
  },
});
