
import { defineComponent, computed } from "vue";
import { Database } from "@/types/Database";
import { Modal } from "ant-design-vue";
import { convertMoney, prettifyNumber } from "@/common/helper";
import { apiStartLicense } from "@/api/licenses";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { CheckCircleFilled } from "@ant-design/icons-vue";

export default defineComponent({
  name: "TheNavBar",
  components: {
    CheckCircleFilled,
  },
  props: {
    database: {
      type: Object as () => Database,
      required: true,
    },
  },
  setup(props) {
    const prettify = prettifyNumber;

    const store = useStore();
    const router = useRouter();

    const credits = computed(() => {
      return store.getters["auth/getCredits"];
    });

    const showConfirmTopUp = () => {
      Modal.confirm({
        okText: "Top up account",
        title: `Insufficient funds`,
        content: `Your account is ${(props.database.price - credits.value).toFixed(
          2
        )} dollars short`,
        onOk: async () => {
          const recommend = (props.database.price - credits.value).toFixed(2);
          await router.push(`/invoice-create/${recommend}`);
        },
        onCancel() {
          // Do nothing
        },
      });
    };

    const showConfirmBuy = () => {
      Modal.confirm({
        okText: "Buy",
        title: `Confirm the purchase database ${props.database.name}?`,
        content: `When you click the Buy button, ${convertMoney(
          props.database.price
        )} will be debited from your balance and a license will be created.`,
        onOk: async () => {
          await apiStartLicense({
            database: props.database.id,
            month: 1,
          });
          await router.push("/");
        },
        onCancel() {
          // Do nothing
        },
      });
    };

    const buyButton = () => {
      if (credits.value < props.database.price) {
        showConfirmTopUp();
      } else {
        showConfirmBuy();
      }
    };

    return { buyButton, prettify };
  },
});
